/**
 * Customizzazioni:
 * - 3 immagini come logo
 * - Proporzioni in base alle richieste del cliente (la A di Archivio deve essere della stessa altezza che il logo a sinistra BIBLIO-TECHE)
 *
 */

import React from 'react';
//eslint-disable-next-line import/no-unresolved
import logoModena from '@package/components/layout/images/LogoModena.png?width=216';
//eslint-disable-next-line import/no-unresolved
import logoBiblio from '@package/components/layout/images/LogoBiblio.png?width=130';
//eslint-disable-next-line import/no-unresolved
import logoArchivioStorico from '@package/components/layout/images/LogoArchivioStorico.png?width=200';

/* import { Icon } from '@italia/components/ItaliaTheme'; */

/*
 * Customization with image
 *
 * If you have a jpg/png, do like this:
 *
 * <figure className="icon">
 *  <img src={logo} alt="" />
 * </figure>
 *
 * Note the icon class.
 */

const LogoFooter = () => (
  <figure className="icon">
    <img src={logoModena} width="216" height="80" alt="Logo Comune di Modena" />
    <img
      src={logoBiblio}
      width="124"
      height="65"
      alt="Logo Biblioteche di Modena"
    />
    <img
      src={logoArchivioStorico}
      width="200"
      height="130"
      alt="Logo Archivio Storico"
    />
  </figure>
);
export default LogoFooter;
