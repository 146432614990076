/**
 * ServizioCorrelati view component.
 * @module components/theme/View/SevizioCorrelati
 *
 * - Added new section luoghi_correlati
 */

import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  RichTextArticle,
  GenericCard,
} from '@italia/components/ItaliaTheme/View';

const messages = defineMessages({
  related_items: {
    id: 'related_items',
    defaultMessage: 'Contenuti correlati',
  },
  servizi_items: {
    id: 'servizi_items',
    defaultMessage: 'Servizi',
  },
  venue_items: {
    id: 'venue_items',
    defaultMessage: 'Luoghi',
  },
  correlated_news: {
    id: 'correlated_news',
    defaultMessage: 'Notizie',
  },
});

const ServizioCorrelati = ({ content }) => {
  const intl = useIntl();
  return content.servizi_collegati?.length > 0 ||
    content.relatedItems?.length > 0 ||
    content.luoghi_correlati?.length > 0 ? (
    <RichTextArticle
      tag_id="correlati"
      title={intl.formatMessage(messages.related_items)}
    >
      {content.servizi_collegati?.length > 0 && (
        <div className="mb-4">
          <h5>{intl.formatMessage(messages.servizi_items)}</h5>
          <div className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal">
            {content.servizi_collegati.map((item, i) => (
              <GenericCard key={item['@id']} item={item} showimage={false} />
            ))}
          </div>
        </div>
      )}
      {content.luoghi_correlati?.length > 0 && (
        <div className="mb-4">
          <h5>{intl.formatMessage(messages.venue_items)}</h5>
          <div className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal">
            {content.luoghi_correlati.map((item, i) => (
              <GenericCard key={item['@id']} item={item} showimage={false} />
            ))}
          </div>
        </div>
      )}

      {content.relatedItems?.length > 0 && (
        <div className="mb-4">
          <h5>{intl.formatMessage(messages.correlated_news)}</h5>

          <div className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal">
            {content.relatedItems.map((item, i) => (
              <GenericCard key={item['@id']} item={item} showimage={false} />
            ))}
          </div>
        </div>
      )}
    </RichTextArticle>
  ) : (
    <></>
  );
};

export default ServizioCorrelati;
