import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  RichTextArticle,
  GenericCard,
} from '@italia/components/ItaliaTheme/View';

const messages = defineMessages({
  servizi_items: {
    id: 'servizi_items',
    defaultMessage: 'Servizi',
  },
  correlated_news: {
    id: 'correlated_news',
    defaultMessage: 'Notizie',
  },
});

const VenueCorrelati = ({ content }) => {
  const intl = useIntl();
  return content.servizi_correlati?.length > 0 ||
    content.relatedItems?.length > 0 ? (
    <RichTextArticle
      tag_id="correlati"
      title={intl.formatMessage(messages.servizi_items)}
    >
      {content.servizi_correlati?.length > 0 && (
        <div className="mb-4">
          <div className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal">
            {content.servizi_correlati.map((item, i) => (
              <GenericCard key={item['@id']} item={item} showimage={false} />
            ))}
          </div>
        </div>
      )}
    </RichTextArticle>
  ) : (
    <></>
  );
};

export default VenueCorrelati;
